<template>
  <div class="feature-news-list w-full flex flex-col md:grid md:grid-rows-3 md:grid-cols-3 bg-darkBluePrimary md:pt-14">
    <div class="news-item first:row-span-full first:col-span-2 group relative cursor-pointer">
      <img
        class="block w-full h-full min-h-[150px] lg:min-h-fit object-fit object-center"
        :src="img_path[0].thumbnail"
      >
      <div v-for="item in news" :key="item.id">
        <div
          class="news-item-content h-full absolute top-0 left-0 bg-black/[.7] w-full p-3 overflow-hidden"
          :class="'md:group-[:not(:first-child)]:hidden md:py-5 md:px-5 md:w-2/3 xl:w-1/2 xl:px-12'"
          @click="goToNewsDetail(item?.id)"
        >
          <div class="text-white font-bold text-2xl uppercase mb-3 md:text-2xl md:font-bold">Tin tức</div>
          <h4 class="text-white font-bold text-lg mb-2 md:text-xl line-clamp-2 2xl:line-clamp-3">
            {{ item?.title }}
          </h4>
          <div class="text-white text-xs hidden sm:block sm:mb-2 md:mb-4">
            {{ item?.post_date }}
          </div>
          <div
            class="text-white hidden sm:mb-2 sm:text-base md:text-base md:mb-6 md:line-clamp-4 2xl:line-clamp-6 text-base"
          >
            {{ item?.highlight }}
          </div>
          <div class="text-orangePrimary flex justify-start items-center group">
            <div class="text-base">{{ $t('Xem nhiều hơn') }}</div>
            <i class="ri-arrow-right-line ml-2 group-hover:ml-4 transition-all duration-1000" />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full bg-[#2078CE] row-span-2">
      <el-carousel trigger="click" arrow="never" class="carousel-news_latest" interval="0">
        <el-carousel-item v-for="newsLatest in fiveNewsLatest" :key="newsLatest.id">
          <div class="w-full h-full lg:p-8 p-3 cursor-pointer" @click="goToNewsDetail(newsLatest?.id)">
            <h4
              class="text-white font-bold text-lg mb-2 md:text-xl line-clamp-2 2xl:line-clamp-3 cursor-pointer"
              style="height: 56px !important"
            >
              {{ newsLatest?.title }}
            </h4>
            <div class="text-white text-xs sm:block sm:mb-2 md:mb-4">
              {{ newsLatest?.post_date }}
            </div>
            <div
              class="text-white sm:mb-2 sm:text-base md:text-base md:mb-6 line-clamp-6 text-base"
            >
              {{ newsLatest?.highlight }}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="w-full relative z-0">
      <img
        class="w-full h-full lg:min-h-fit object-fit object-center bg-black opacity-60"
        :src="img_path[3].thumbnail"
      >
      <button
        class="absolute bg-[#F49D2E] text-white px-4 py-2 top-1/2 left-[30px] transform -translate-y-1/2 z-1"
        @click="goToNewsPage()"
      >
        Xem toàn bộ các tin tức
      </button>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import axios from '@/plugins/axios'
export default {
  data() {
    return {
      news: [],
      fiveNewsLatest: [],
      img_path: [
        {
          thumbnail: '/images/user/news/news-1.jpg',
        },
        {
          thumbnail: '/images/user/news/news-2.png',
        },
        {
          thumbnail: '/images/user/news/news-3.png',
        },
        {
          thumbnail: '/images/user/news/news-4.png',
        },
      ],
    }
  },
  async created() {
    await this.fetchData()
    await this.getFiveLatestNewsDisplayOnHomePage()
  },
  methods: {
    goToNewsDetail(new_id) {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('news.news-detail', { id: new_id }))
    },
    async fetchData() {
      const { status, data } = await axios.get('/get-new-for-home-page')
      if (status === 200) {
        this.news = data?.data
      }
    },
    goToNewsPage() {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('news.list'))
    },
    async getFiveLatestNewsDisplayOnHomePage() {
      const { status, data } = await axios.get('/get-five-latest-news-for-home-page')
      if (status === 200) {
        this.fiveNewsLatest = data?.data
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return new dayjs(date).format('DD-MM-YYYY')
    },
  },
}
</script>
<style>
.carousel-news_latest ul li.el-carousel__indicator--horizontal button.el-carousel__button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 100%;
}
.carousel-news_latest ul li.el-carousel__indicator--horizontal.is-active button.el-carousel__button {
  background-color: #f49d2e !important;
}
</style>
